import React, {useState} from 'react';
import './App.css';
import Header from './Components/Header'
import EditTable from './Components/EditTable'
import ShotGraph from "./Components/ShotGraph";
import Footer from "./Components/Footer";
import { ConfirmProvider } from 'material-ui-confirm';
require("firebase/firestore");


function App() {
  return (
      <ConfirmProvider>
        <div className="App">
          <Header/>
          <EditTable/>
          <ShotGraph/>
          <Footer/>
        </div>
      </ConfirmProvider>
  );
}

export default App;
