import * as React from 'react';
import {useEffect} from 'react';
import {
    Chart,
    BarSeries,
    LineSeries,
    ArgumentAxis,
    ValueAxis,
    Title,
    Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation } from '@devexpress/dx-react-chart';
import {getNumberOfShotjes} from "../shotjesDao";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";


export default function ShotGraph() {
    const classes = makeStyles((theme) => ({
        root: {
            maxWidth: 345,

            height: 0,
            paddingTop: '56.25%', // 16:9


            transform: 'rotate(0deg)',
            marginLeft: 'auto',

        },
    }));

    var [data, setData] = React.useState([]);

    useEffect(() => {

        async function fetchProduct() {

            setData(await getNumberOfShotjes());

        }
        fetchProduct()

    }, []);


        return (
            <Card className={classes.root}>
                <Chart
                    data={data}
                >

                    <BarSeries
                        name="Uitdelen"
                        valueField="aantalUitdelen"
                        argumentField="naam"
                        color="#4050B5"/>
                    <BarSeries
                        name="Ontvangen"
                        valueField="aantalOntvangen"
                        argumentField="naam"
                        color="#C90076"/>
                    <Legend
                        position= 'top'
                    />
                    <ArgumentAxis  />
                    <ValueAxis  showGrid={false} allowDecimals={false} />
                    <Title text="Ranking the shotjes" />
                    <Animation />
                </Chart>
            </Card>
        );
}
